import 'slick-carousel';
import "./sass/home.scss";
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();
import objectFitImages from 'object-fit-images';
objectFitImages();


$(function (){
  $(document).ready(function(){
    $('.wrap').addClass('ready');
  });
  $(window).on('load', function() {
    $('.wrap').addClass('comp');
    // heightResize();
  });
  $(window).resize(function() {
    // heightResize();
  });

  $('.firstview_slider').on('init', function(){
    $('.slick-slide[data-slick-index="0"]').addClass('zooming');
  })
  $('.firstview_slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    pauseOnHover: false,
    infinite: true,
    arrows: false,
    dots: false,
    fade: true
  })
    .on({
      beforeChange: function(event, slick, currentSlide, nextSlide) {
        $(".slick-slide", this).eq(currentSlide).addClass("preve-slide");
        $(".slick-slide", this).eq(nextSlide).addClass("zooming");
      },
      afterChange: function(event, slick, currentSlide) {
        $(".preve-slide", this).removeClass("preve-slide zooming");
      }
    });

  $('.firstview_slider').on('init', function(){
    $('.slick-slide[data-slick-index="0"]').addClass('zooming');
  });


  /* ----------------------------------
  メニュートグル
  ---------------------------------- */
  $('.gNav_toggle').on('click', function () {
    $(this).toggleClass('is-active');
    $(this).next('.gNav_overlay').toggleClass('is-open');
    if ($("body").css('overflow') == 'hidden') {
      $("body").css({ overflow: 'visible' });
    } else if ($("body").css('overflow') == 'visible') {
      $("body").css({ overflow: 'hidden' });
    };
  });

  $('.gNav_list_item_toggle').on('click', function () {
    $(this).toggleClass('is-active');
    $(this).next('.gNavSub').slideToggle();
  });


  // #で始まるアンカーをクリックした場合に処理
  $('.smoothScroll').click(function(){
    // 移動先を50px上にずらす
    const adjust = 90;
    // スクロールの速度
    const speed = 400; // ミリ秒
    // アンカーの値取得
    const href= $(this).attr("href");
    // 移動先を取得
    const target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を調整
    const position = target.offset().top - adjust;
    // スムーススクロール
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });

});


/* ----------------------------------
ローディング
---------------------------------- */

var loading = document.getElementById('loading');
/* 読み込み完了 */
window.addEventListener('load', stopload);

/* ロード画面を非表示にする処理 */
function stopload(){
  loading.classList.add('is-loaded');
}

/* 10秒経ったら強制的にロード画面を非表示にする */
setTimeout(stopload,10000, '');

document.addEventListener('DOMContentLoaded', function() {
});

const setFillHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

let vw = window.innerWidth;

window.addEventListener('resize', () => {
  if (vw === window.innerWidth) {
    // 画面の横幅にサイズ変動がないので処理を終える
    return;
  }

  // 画面の横幅のサイズ変動があった時のみ高さを再計算する
  vw = window.innerWidth;
  setFillHeight();
});

// 初期化
setFillHeight();